import { useStaticQuery, graphql } from "gatsby"

export const queryAllYaml = () => {
  const {
    allTopics,
    allManageTopics,
    manageArticles,
    discoverArticles,
    usefulLinks,
    womenVoices,
  } = useStaticQuery(graphql`
    query {
      allTopics: allTopicsYaml {
        edges {
          node {
            id
            icon
            title
          }
        }
      }
      allManageTopics: allManageTopicsYaml {
        edges {
          node {
            id
            icon
            title
          }
        }
      }
      womenVoices: allWomenvoicesYaml {
        edges {
          node {
            id
            topic
            videos {
              title
              videoURL
              thumbnail {
                childImageSharp {
                  fluid(maxWidth: 260) {
                    aspectRatio
                    base64
                    sizes
                    src
                    srcSet
                  }
                }
              }
              name
              order
            }
          }
        }
      }
      manageArticles: allManageArticlesYaml(
        filter: { path: { ne: "DONT-DELETE" } }
      ) {
        edges {
          node {
            path
            title
            description: excerpt
            last_updated_at
            read_time
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 1440) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
      }
      discoverArticles: allArticlesYaml(
        filter: { path: { ne: "DONT-DELETE" } }
        sort: { fields: order }
      ) {
        edges {
          node {
            path
            title
            description: excerpt
            last_updated_at
            read_time
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 1440) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
      }

      usefulLinks: allUsefulLinksYaml {
        edges {
          node {
            title
            link
          }
        }
      }
    }
  `)
  return {
    allTopics,
    allManageTopics,
    manageArticles,
    discoverArticles,
    usefulLinks,
    womenVoices,
  }
}
