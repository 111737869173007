import React from "react"
import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"
import styled from "styled-components"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"
import { breakpoints } from "../utils"

const RightArrow = styled(FaChevronRight)`
  position: absolute;
  right: -1.8%;
  padding-left: 5px;
  top: calc(50% - 26px);
  cursor: pointer;
  user-select: none;
  @media (max-width: ${breakpoints.tablet}px) {
    position: absolute;
    right: -10px;
    top: calc(50% - 26px);
    cursor: pointer;
    user-select: none;
  }
  @media (max-width: ${breakpoints.phone}px) {
    right: 13px;
    top: calc(50% - 26px);
`

const LeftArrow = styled(FaChevronLeft)`
  position: absolute;
  left: -1.8%;
  top: calc(50% - 26px);
  cursor: pointer;
  user-select: none;
  @media (max-width: ${breakpoints.tablet}px) {
    position: absolute;
    left: -15px;
    top: calc(50% - 26px);
    cursor: pointer;
    user-select: none;
  }
  @media (max-width: ${breakpoints.phone}px) {
    left: 10px;
    
    top: calc(50% - 26px);
`

const defaultResponsiveCfg = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 4 },
  1600: { items: 5 },
}

interface Props {
  ref?: any
  readonly items: React.ReactNode[]
  readonly touchTracking?: boolean
  readonly hideNavBtns?: boolean
  readonly hideDots?: boolean
  readonly responsive?: any
  readonly disableResponsive?: boolean
  readonly responsiveCfg?: any
  readonly activeIndex?: number
  readonly onSlideChanged?: Function
  readonly autoPlay?: boolean
  readonly leftNavBtnStyles?: any
  readonly rightNavBtnStyles?: any
  readonly infinite?: boolean
  readonly LeftArrowComp?: any
  readonly RightArrowComp?: any
  readonly autoHeight?: any
}

const renderNavButton = (Btn: any) => ({ isDisabled, ...restProps }: any) => {
  const color = isDisabled ? "rgba(0, 0, 0, 0.25)" : "#F54471"
  return <Btn color={color} {...restProps} />
}

const Carousel: React.FC<Props> = ({
  ref,
  items,
  disableResponsive,
  responsiveCfg,
  activeIndex,
  onSlideChanged,
  touchTracking = true,
  hideNavBtns = false,
  hideDots = true,
  autoPlay = false,
  infinite = false,
  LeftArrowComp = null,
  RightArrowComp = null,
  autoHeight = false,
}) => {
  let responsive
  if (!disableResponsive) {
    responsive = responsiveCfg ? responsiveCfg : defaultResponsiveCfg
  }
  const props: any = {
    ref,
    animationType: "slide",
    touchTracking,
    mouseTracking: true,
    disableButtonsControls: hideNavBtns,
    disableDotsControls: hideDots,
    items,
    responsive,
    /* renderPrevButton: renderNavButton(LeftArrowComp || LeftArrow),
    renderNextButton: renderNavButton(RightArrowComp || RightArrow), */
    renderPrevButton: renderNavButton(LeftArrow),
    renderNextButton: renderNavButton(RightArrow),
    autoPlay,
    autoWidth: true,
    autoHeight: autoHeight,
    infinite,
    autoPlayInterval: 2000,
  }
  console.log("activeIndex", activeIndex)
  if (activeIndex) {
    props.activeIndex = activeIndex
  }
  if (onSlideChanged) {
    props.onSlideChanged = onSlideChanged
  }
  // if (
  //   typeof window !== `undefined` &&
  //   window.innerWidth < breakpoints.desktop
  // ) {
  //   props.disableButtonsControls = true
  // }
  return <AliceCarousel {...props} />
}

export default Carousel
